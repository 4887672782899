import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import LatestVehicles from "themes/module_latest_vehicles_slider/static/js/latestVehicles";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";

import RecentlyVehiclesSlider from "themes/module_recent_vehicles_slider/static/js/recentVehiclesSlider";

export default class HomePage {
  public static init(siteConfig: SiteConfig, pageConfig: PageConfig) {
    Page.ready(function () {
        $('.slick--home-slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: "<span class='slick-prev main'>&lt;</span>",
          nextArrow: "<span class='slick-next main'>&gt;</span>",
          autoplay: true,
          autoplaySpeed: 3000
        });

        $('.slick--logo-search').slick({
          vertical: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          prevArrow: "<span class='slick-prev main'>&lt;</span>",
          nextArrow: "<span class='slick-next main'>&gt;</span>",
          autoplay: false,
          autoplaySpeed: 3500,
          adaptiveHeight: true,
          speed: 1750,
          swipeToSlide: true,
          mobileFirst: true,
          responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  autoplay: false
              }
          },
          {
            breakpoint: 1023,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                autoplay: false
            }
          }]
        });
      
    $('.slick--body-search').slick({
      vertical: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: true,
      prevArrow: "<span class='slick-prev main'>&lt;</span>",
      nextArrow: "<span class='slick-next main'>&gt;</span>",
      autoplay: false,
      autoplaySpeed: 3500,
      adaptiveHeight: true,
      speed: 1750,
      mobileFirst: true,
      responsive: [
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              autoplay: false
          }
      },
      {
        breakpoint: 1023,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: false
        }
      }]
    });
    
    });
    const financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
    // init latest vehicles on home page (not in sidebar like other pages)
    LatestVehicles.init(siteConfig.latestVehiclesConfig.home, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("home") != -1),financeSearchTerms, siteConfig);

    LatestVehicles.init(siteConfig.latestVehiclesConfig.main, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("main") != -1),financeSearchTerms, siteConfig);
    
    RecentlyVehiclesSlider.init("recentlyadded");
    RecentlyVehiclesSlider.init("recentlysold");
  }  
}
